interface CookiePreference {
  cookieGroupId: string;
  enabled: boolean;
  name?: string;
}

interface DataInterface {
	"CFAID": string;
	"Authenticated": boolean;
	"Bearer": string;
}

interface ConsentObject {
  cookieGroupId: string;
  enabled: boolean;
}

const rejectedPreferences: CookiePreference[] = [
	{ cookieGroupId: "1", enabled: true },
	{ cookieGroupId: "2", enabled: false },
	{ cookieGroupId: "3", enabled: false },
	{ cookieGroupId: "4", enabled: false },
]

const acceptAllPreferences: CookiePreference[] = [
	{ cookieGroupId: "1", enabled: true },
	{ cookieGroupId: "2", enabled: true },
	{ cookieGroupId: "3", enabled: true },
	{ cookieGroupId: "4", enabled: true },
]


function getOptanonCookie(){
	const cookies = document.cookie;
  const optanonCookie = cookies
    .split("; ")
    .find((cookie) => cookie.startsWith("OptanonConsent="));

	return optanonCookie
}

async function getBearerTokenFromGPC(): Promise<string | null> {
  const endpoint: string = "https://www.chick-fil-astage.com/gpc/jwtbearer";

  try {
    const response: Response = await fetch(endpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data: DataInterface = await response.json();

		if(!data["Authenticated"]) {
			console.error("User not authenticated.")
			return null;
		}

    return data.Bearer;
  } catch (error) {
    console.error("Error fetching GPC bearer token:", error);
    return null;
  }
}
function setOptanonConsentCookie(value: string) {
  document.cookie = `${value}; path=/; domain=.chick-fil-a.com`;
}

function serializeToCookie(data: any[]) {
  return data
    .filter(item => item.includes('='))
    .map(item => {
      const [key, value] = item.split('=', 2);
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join('&');
}

function updateCookiePreferences(preferences: CookiePreference[]) {
	const optanonCookie = getOptanonCookie();
	const splitedCookie = optanonCookie?.split('&')

	if(!splitedCookie) {
		console.error("Optanon cookie not found")
		return null
	}

	let groupsIndex = -1;
	for (let i = 0; i < splitedCookie.length; i++) {
			if (splitedCookie[i].startsWith("groups=")) {
					groupsIndex = i;
					break;
			}
	}

	splitedCookie[groupsIndex] = `groups=${preferences.map((item) => `${item.cookieGroupId}:${item.enabled ? 1 : 0}`).join(",")}`;

	const cookieString = serializeToCookie(splitedCookie);
	console.log("New cookie string:", cookieString);
	setOptanonConsentCookie(cookieString);
}

async function getUserPreferences() {
  const bearerToken = await getBearerTokenFromGPC();
  if (!bearerToken) {
    return null;
  }

  const endpoint = "https://d2c.api.qa.crndev.chick-fil-a.com/cfa.frontend.preferences.v1.PreferencesService/GetCookiePreferences";

  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${bearerToken}`,
        'Content-Type': 'application/json',
      },
			body: JSON.stringify({})
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
		updateCookiePreferences(data.cookies);
		console.log("User preferences fetched");
  } catch (error) {
    console.error("Error fetching user preferences:", error);
    return null;
  }
}

function transformConsentString(consentString: string | null): ConsentObject[] | null {
	if (!consentString) {
    return null;
  }

  return consentString.split(',').map(item => {
    const [id, value] = item.split(':');
    return {
      cookieGroupId: id,
      enabled: value === '1' ? true : false,
    };
  });
}

function getOptanonConsent(): ConsentObject[] | null {

	const optanonCookie = getOptanonCookie();

  const groupsValue = optanonCookie
    ?.match(/&groups=([^&]*)/)?.[1];

  const groupsSanitized = groupsValue
    ?.replace(/%3A/g, ":")
    .replace(/%2C/g, ",");

  return groupsSanitized ? transformConsentString(groupsSanitized) : null;
}

async function upsertCookiePreferences(preferences: CookiePreference[]): Promise<boolean> {
  const bearerToken: string | null = await getBearerTokenFromGPC();
  if (!bearerToken) {
    return false;
  }

  const endpoint: string = "https://d2c.api.qa.crndev.chick-fil-a.com/cfa.frontend.preferences.v1.PreferencesService/UpsertCookiePreferences";

  try {
    const response: Response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${bearerToken}`
      },
      body: JSON.stringify({
        cookies: preferences,
        externalGPC: (navigator as any).globalPrivacyControl ? true : false,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return true;
  } catch (error) {
    console.error("Error updating cookie preferences:", error);
    return false;
  }
}

async function handleCookiePreferenceChange(preferences: CookiePreference[]): Promise<void> {
  const success: boolean = await upsertCookiePreferences(preferences);
  if (success) {
    console.log("Cookie preferences updated successfully.");
  } else {
    console.error("Failed to update cookie preferences.");
  }
}

function triggerPoliciesChange() {
  const currentConsent = getOptanonConsent();
  if (!currentConsent) return null

	handleCookiePreferenceChange(currentConsent);
}

function addListeners() {
  const openOneTrustButtons = document.querySelectorAll<HTMLElement>("#onetrust-pc-btn-handler, .optanon-toggle-display");
	const rejectNonEssentials = document.querySelectorAll<HTMLElement>("#onetrust-reject-all-handler");
	const acceptAll = document.querySelectorAll<HTMLElement>("#onetrust-accept-btn-handler");

  openOneTrustButtons.forEach((button) => {
    button.addEventListener("click", () => {
      console.log("One trust banner opened using", button);
      const saveChangesBtn = document.querySelector<HTMLInputElement>(".save-preference-btn-handler");
			const rejectBtn = document.querySelector<HTMLInputElement>(".ot-pc-refuse-all-handler, ");

      saveChangesBtn?.addEventListener("click", () => {
        console.log("One trust banner preferences saved");
        triggerPoliciesChange();
      });

			rejectBtn?.addEventListener("click", () => {
				console.log("Reject non essentials button clicked");
				upsertCookiePreferences(rejectedPreferences);
			});
    });
  });

	rejectNonEssentials.forEach((button) => {
		button.addEventListener("click", () => {
			console.log("Reject non essentials button clicked");
			upsertCookiePreferences(rejectedPreferences);
		});
	});

	acceptAll.forEach((button) => {
		button.addEventListener("click", () => {
			console.log("Reject non essentials button clicked");
			upsertCookiePreferences(acceptAllPreferences);
		});
	});
}

setTimeout(() => {
	getUserPreferences();
	addListeners();
}, 3000)

